
.btn-main {
    width: fit-content;
    background: transparent;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 46px;
    color: $light-color;
    text-transform: unset;
    font-weight: 400;
    cursor: pointer;
    font-size: 16px;
    transition: $hover;
    border: 1px solid $white;
    z-index: 2;
    @extend %font-subtitle;
    &:hover {
        color: $white;
        background: none;
        border: 1px solid $dark; } }
.btn-primary {
    width: fit-content;
    background: $gradietn-green;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 42px;
    font-size: 14px;
    color: $mainFontColor;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5625rem;
    letter-spacing: 0.02875rem;
    cursor: pointer;
    transition: $hover;
    border: 1px solid $default-color;
    &:hover::before {
        opacity: 1;
 }        // background: $goldGradient
    &:hover {
        background: $gradietn-green-hover;
        color: $dark; } }

*::-webkit-scrollbar {
    width: 7Q;
    height: 7Q;
    background-color: #e5e9f2; }

body::-webkit-scrollbar-thumb,
body *::-webkit-scrollbar-thumb {
    background: $hover-color;
    // background: url(../images/hover-button.gif)
    // background-size: cover
    // background-position: left center
 }    // background-repeat: no-repeat
body {
    &::after {
        background-image: url(../images/garry.png);
        content: " ";
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 500;
        pointer-events: none;
        opacity: .6; } }
*::selection {
    background: $default-color; }
*::-moz-selection {
    background: $default-color; }

$shakeVertical: shakeVertical 2s cubic-bezier(0.76, 0, 0.24, 1) 0s infinite alternate both;
@keyframes shakeVertical {
    0% {
        transform: scaleY(1);
        transform-origin: 0% 100%; }

    100% {
        transform: scaleY(0.4);
        transform-origin: 0% 100%; } }
