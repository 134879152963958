.nav-pills {
    gap: 40px;
    @include media-breakpoint-down(lg) {
        gap: 10px;
        justify-content: space-between; }
    .nav-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 143%;
        letter-spacing: 0.01063rem;
        text-transform: uppercase;
        @extend %font-primary; }
    .nav-link {
        color: $mainFontColor;
        padding: 0px 0px 0px 0px;
        &.active {
            background-color: transparent;
            color: $mainFontColorSubtitle;
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 18px;
                bottom: 0;
                background-image: url('../../images/d4-tab-underline-21.png');
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat; } } } }
