.main {
    // position: relative
    &__promo {
        // position: relative
        // z-index: 1
        // top: 0px
        .greeting-scroll {
            color: #D50000;
            position: absolute;
            z-index: 10;
            bottom: 0px;
            right: 5%;
            transform: translate(0, -2%);
            max-height: 134px;
            max-width: 26px;
            width: 100%;
            height: 100%;
            &__gif {
                position: absolute;
                top: 30px;
                left: 20px;
                transform: rotate(90deg);
                max-width: 42px;
                max-height: 42px; }
            &__text {
                position: absolute;
                bottom: 1rem;
                left: 3px;
                font-size: 10px;
                @extend %font-default;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                letter-spacing: 0.01063rem;
                color: $default-color;
                transform: rotate(90deg); }
            &__scroll {
                height: 70%;
                background: #2E2D2D;
                display: block;
                position: relative;
                top: 30%;
                left: 60%;
                // transform: translate(-60%, 0)
                width: 1px;
                .greeting-scroll__thumb {
                    height: 80%;
                    position: absolute;
                    background: $default-color;
                    width: 1px;
                    bottom: 0;
                    left: 0;
                    animation: $shakeVertical; } } } } }
.page {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: left;
    min-width: 320px;
    max-width: 1920px;
    transition: margin .2s ease;
    position: relative;
    // height: 100vh
    overflow: hidden;
    // Fix for IE11
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block; }
    &__content {
        flex: 1; } }
.greeting {
    position: relative;
    z-index: 10;
    padding-top: 136px !important;
    padding-bottom: 374px !important;
    // margin-top: 300px !important
    // margin-bottom: 300px !important
    // &::before
    //     content: ''
    //     position: absolute
    //     bottom: -2%
    //     width: 100%
    //     height: 58px
    //     z-index: 10
    //     background: linear-gradient(180deg, rgba(39, 39, 39, 0.00) 0%, #1C1C1C 68.23%, rgba(28, 28, 28, 0.31) 83.85%, rgba(28, 28, 28, 0.00) 100%)
    //     z-index: 101
    &__logo {
        display: flex;
        align-items: center;
        justify-content: center; }
    &__header {
        .content-logo {
            max-width: 564px;
            max-height: 270px;
            width: 100%;
            height: 100%; }
        .content-title {
            @extend %font-default;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 2.25rem;
            letter-spacing: 0.02875rem;
            color: $mainFontColor; }
        .content-btn {
            margin: 0 auto;
            z-index: 100;
            background: $gradient-cal;
            border: 1px solid #271813;
            &:hover {
                background: $gradient-cal-hover; } } } }
