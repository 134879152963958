.mainmenu {
    $self: &;

    /**
     * All viewports
     */
    &__list {
        font-size: 14px;
        line-height: normal;
        @extend %font-subtitle;
        font-style: normal;
        font-weight: 400;
        line-height: 143%;
        letter-spacing: 0.01063rem;
        text-transform: uppercase;

        li {
            position: relative;
            // &:nth-child(2)
            //     // border: 1px solid $white
            //     padding: 0px 10px
            //     border-right: 1px solid transparent
            //     border-left: 1px solid transparent
            //     border-top: none
            //     border-bottom: none
            //     border-image: $goldGradient
            //     -moz-border-image: $goldGradient
            //     -webkit-border-image: $goldGradient
            //     border-image-slice: 1
            //  // content: ''
            //  // display: block
            //  // width: 1px
            //  // height: 100%
            //  // background: $goldGradient
            //  // right: -20%
            //  // transform: translate(20%, 0)
            //  // position: absolute
            //  // @include media-breakpoint-down(lg)
            //  //     display: none
            a {
                color: $white;
                text-decoration: none;
                transition: $hover;
                &:hover {
                    color: $hover-color;
                    // @extend %text-gradient
 } }                    // background: $goldGradient

            &.is-active {
                a {
                    color: $white;
                    &::after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 1px;
                        background: $hover-color;
                        top: 1px; } } } } }

    /**
     * Mobile
     */
    @include media-breakpoint-down(lg) {
        background-color: $white;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        overflow-y: auto;
        padding: 20px;
        transform: translateX(-100%);
        transition: transform 0.3s ease;
        width: 260px;
        z-index: 102;

        &.is-active {
            transform: translateX(0);
            transition: transform 0.3s ease; }

        &__close-btn {
            position: absolute;
            top: 12px;
            right: 12px;
            font-size: 14px; }

        &__list {
            font-weight: 500;

            li {
                a {
                    color: $gray-600;
                    &:hover {
                        text-decoration: none; } }

                &:not(:last-child) {
                    margin-bottom: 20px; } } } }

    /**
     * Desktop
     */
    @include media-breakpoint-up(lg) {
        &__list {
            display: flex;
            justify-content: flex-start;

            > #{$self}__item {
                &:not(:last-child) {
                    margin-right: 20px;

                    @include media-breakpoint-up(xl) {
                        margin-right: 40px; } }

                > a {
                    padding-top: 5px;
                    padding-bottom: 5px; } } }

        &__item {
            a {
                color: $dark;
                position: relative;
                display: block;
                transition: color .2s ease;

                &:hover {
                    text-decoration: none;
                    transition: color .2s ease; } } }

        // Dropdowns
        &__dropdown {
            background-color: $white;
            box-shadow: 0 0 5px 0 rgba($black, 0.2);
            padding: 10px 15px;
            font-size: 14px;
            position: absolute;
            top: 30px;
            left: 0;
            margin-top: 15px;
            opacity: 0;
            transition: opacity, margin-top .2s ease;
            min-width: 150px;

            #{$self}__item {
                a {
                    display: block;
                    padding-top: 8px;
                    padding-bottom: 8px; } } }

        // Parent
        &__item--parent {
            position: relative;
            z-index: 10;

            &:hover {
                #{$self} {
                    &__dropdown {
                        transition: opacity, margin-top .2s ease;
                        margin-top: 0;
                        opacity: 1; } } }

            > a {
                display: flex;
                align-items: center;

                &::after {
                    border-top: 2px solid $dark;
                    border-right: 2px solid $dark;
                    content: "";
                    display: block;
                    margin-left: 8px;
                    transform: rotate(135deg);
                    height: 7px;
                    width: 7px; }

                &:hover {
                    &::after {
                        border-top-color: $primary;
                        border-right-color: $primary; } } } } } }
