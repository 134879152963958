.header {
    background-color: $headerColor;
    // border-bottom: 1px solid rgba(50, 50, 50, 0.25)
    z-index: 101;
    position: relative;
    padding: 4px 10px;
    @include media-breakpoint-down(m) {
 }        // padding: 5px 0px
    // .content-btn
    //     background: $primary-color
    //     border: 1px solid $primary-color
    //     padding: 6px 50px
    //     font-size: 16px
    //     &:hover
    //         background: $default-color
    //         border: 1px solid $default-color
 }    //         color: $dark
.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-up(xl) {
        justify-content: center; }
    @include media-breakpoint-down(m) {
        justify-content: center; } }
.logo-width {
    width: 4.33333%; }
