/* Include fonts
 @mixin ======================================================================== */

/**
 * Roboto
 */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Oswald:wght@200;300;400;500;600;700&display=swap');

// Example include webfont
// @include fontFace('Font name', 'filename', 'font-weight-normal', 'font-style-normal', 'woff2')

// peremen
%font-default {
   font-family: 'Minecraft Rus';
   font-style: normal; }
%font-subtitle {
   font-family: 'Minecraft Evenings';
   font-style: normal; }
%font-primary {
   font-family: 'Futura PT';
   font-style: normal; }
%font-dop {
   font-family: 'Minecraft Rus';
   font-style: normal; }
//

%text-gradient {
   -webkit-background-clip: text !important;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent; }


@font-face {
	font-family: 'Futura PT';
	font-display: swap;
	src: url("../fonts/futura-pt-bold-589e44b6aacd3.otf") format("otf");
	font-weight: 700;
	font-style: normal; }
@font-face {
	font-family: 'Futura PT';
	font-display: swap;
	src: url("../fonts/futura-pt-bold-oblique-589e453384a18.otf") format("otf");
	font-weight: 700;
	font-style: italic; }
@font-face {
	font-family: 'Futura PT';
	font-display: swap;
	src: url("../fonts/futura-pt-book-589a6dec272c3.otf") format("otf");
	font-weight: 400;
	font-style: normal; }
@font-face {
	font-family: 'Futura PT';
	font-display: swap;
	src: url("../fonts/futura-pt-book-oblique-589e44623c7b4.otf") format("otf");
	font-weight: 400;
	font-style: italic; }
@font-face {
	font-family: 'Futura PT';
	font-display: swap;
	src: url("../fonts/futura-pt-demibold-589e43b852117.otf") format("otf");
	font-weight: 600;
	font-style: normal; }
@font-face {
	font-family: 'Futura PT';
	font-display: swap;
	src: url("../fonts/futura-pt-demibold-oblique-589e43ec3ea82.otf") format("otf");
	font-weight: 600;
	font-style: italic; }
@font-face {
	font-family: 'Futura PT';
	font-display: swap;
	src: url("../fonts/futura-pt-heavy-589a6dd12187e.otf") format("otf");
	font-weight: 800;
	font-style: normal; }
@font-face {
	font-family: 'Futura PT';
	font-display: swap;
	src: url("../fonts/futura-pt-heavy-oblique-589a6e480ab16.otf") format("otf");
	font-weight: 800;
	font-style: italic; }
@font-face {
	font-family: 'Futura PT';
	font-display: swap;
	src: url("../fonts/futura-pt-light-589a6e187563a.otf") format("otf");
	font-weight: 300;
	font-style: normal; }
@font-face {
	font-family: 'Futura PT';
	font-display: swap;
	src: url("../fonts/futura-pt-light-oblique-589e448ae90e2.otf") format("otf");
	font-weight: 300;
	font-style: italic; }
@font-face {
	font-family: 'Futura PT';
	font-display: swap;
	src: url("../fonts/futura-pt-medium-589e45b956de4.otf") format("otf");
	font-weight: 500;
	font-style: normal; }
@font-face {
	font-family: 'Futura PT';
	font-display: swap;
	src: url("../fonts/futura-pt-medium-oblique-589e460871ec2.otf") format("otf");
	font-weight: 500;
	font-style: italic; }
@font-face {
	font-family: 'Minecraft Rus';
	font-display: swap;
	src: url("../fonts/minecraft.ttf") format("ttf");
	font-weight: 500;
	font-style: italic; }
@font-face {
	font-family: 'Minecraft Evenings';
	font-display: swap;
	src: url("../fonts/MinecraftEvenings-RBao.ttf") format("ttf");
	font-weight: 500;
	font-style: italic; }
