$customVar: '';

// $mainFont                : 'Exocet', 'Exocet-reaper', Arial, sans-serif;


// Size
$mainFontColor           : #FFFFFF;
$mainFontColorSubtitle   : #D3D3D3;
$mainFontSize            : 16px;
$mainFontWeight          : 400;
$mainLineHeight          : 1.4;

// $desktopWidth            : 1280px;
// $smDesktopWidth          : 1024px;
// $tableWidth              : 768px;
// $smTableWidth            : 640px;
// $phoneWidth              : 480px;
// $smPhoneWidth            : 320px;

// Main color
$default-color           : #87A04E;
$primary-color           : #87A04E;
$success-color           : #4B423C;
$danger-color            : #D50000;
$warning-color           : #171717;
$light-color             : #FFFFFF;
$purple-color            : #8854d0;
$hover-color             : #EFA552;

// Neutral Color
$neutral-primary         : #303133;
$neutral-regular         : #606266;
$neutral-secondary       : #909399;
$neutral-placeholder     : #C0C4CC;

// Border Color
$border-base             : #DCDFE6;
$border-light            : #E4E7ED;
$border-lighter          : #EBEEF5;
$border-lightex          : #F2F6FC;

// btn-color
$btn-color-default          :#CD412B;


$headerColor: rgba(0, 0, 0, 0.20);
// Btn color
$backgroundColorMainBtn     : #A70200;

// Transitions
$hover: .2s cubic-bezier(.455,.03,.515,.955);

// gradient
$overflowLiner: linear-gradient(180deg, rgba(9, 9, 9, 0.00) 0%, rgba(9, 9, 9, 0.57) 22.8%, rgba(9, 9, 9, 0.69) 30.43%, rgba(9, 9, 9, 0.92) 42.08%, #090909 50.07%, rgba(9, 9, 9, 0.92) 56.32%, rgba(9, 9, 9, 0.81) 63.15%, rgba(3, 3, 3, 0.15) 79.17%, rgba(20, 20, 20, 0.00) 99.94%);

$gradient-cal: linear-gradient(90deg, #452F25 0%, #563B2F 96.67%);
$gradient-cal-hover: linear-gradient(90deg, #A95C3A 0%, #23120A 96.67%);
$gradietn-green:  linear-gradient(90deg, #6A973A 0%, #1C3A08 96.67%);
$gradietn-green-hover:  linear-gradient(90deg, #2D4D0B 0%, #66DB19 96.67%);
