@use "sass:math";

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
}

.icon-arrow-up {
    width: (32 / 32) * 1em;
}
.icon-close {
    width: (24 / 24) * 1em;
}
.icon-paginator {
    width: (35 / 35) * 1em;
}
.icon-paginator-left {
    width: (35 / 35) * 1em;
}
.icon-paginator-right {
    width: (35 / 35) * 1em;
}
