.carousel {
    &::before {
        content: '';
        position: absolute;
        display: block;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        background: url(../images/mask-slider.png);
        z-index: 1;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 97% auto; }
    .carousel-inner {}
    // padding: 14px
    // border: 1px solid $default-color
    // z-index: 15
    // -webkit-mask-position: left bottom
    // mask-position: left bottom
    // -webkit-mask-size: 624px 352px
    // mask-size: 624px 352px
    // mask-image: url(../../images/icons/rame.svg)
    // -webkit-mask-image: url(../../images/icons/rame.svg)
    // top: -1px
    // left: -1px
    // &::before
    //     content: ''
    //     width: 115%
    //     height: 100%
    //     display: block
    //     // background-image: url(../../images/rame.png)
    //     top: 0
    //     z-index: 1
    //     position: absolute
    //     left: 50%
    //     transform: translate(-50%, 0px)
    //     background-repeat: no-repeat
    //     background-position: center center
 }    //     background-size: cover
