.logo {
    // max-width: 64px
    // min-height: 64px
    &__link {
        overflow: hidden;
        svg {
            width: 196px;
            height: 36px;
            margin-left: -40px; }
        // img
        //     width: 310%
        //     height: 100%
        //     object-fit: auto
 } }        //     object-position: center left

.icon {}

