.footer {
    // z-index: 1
    // color: $white
    // border-top: solid 1px $mainFontColorSubtitle
    position: relative;
    // background: #101010
    // border-top: 1px solid rgba(177, 177, 177, 1)
    background-image: url(../images/bg-wool-dark.jpg);
    background-position: center center;
    background-repeat: repeat;
    background-size: auto 100%;
    padding-top: 56px;
    padding-bottom: 14px;
    z-index: 33;
    // border-bottom: 1px solid rgba(177, 177, 177, 1)
    &__background {
        .footer-background-left {
            position: absolute;
            left: 0;
            bottom: 0;
            object-fit: contain;
            object-position: center;
            z-index: 1010; }
        .footer-background-right {
            position: absolute;
            right: 0;
            bottom: 0;
            object-fit: contain;
            object-position: center; } }
    &__images {
        width: 100%;
        position: relative;
        &::before {
            content: '';
            display: block;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            position: absolute;
            // margin-right: 20px
            width: 45%;
            height: 1px;
            background: #F6CB05; }
        &::after {
            content: '';
            display: block;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            position: absolute;
            // margin-right: 20px
            width: 45%;
            height: 1px;
            background: #F6CB05; }
        .footer-logo {
            margin: 0 auto;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: center; } }

    &__footer {
        position: relative;
        // z-index: 100
        .content-description {
            @extend %font-primary;
            color: $mainFontColorSubtitle;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.01063rem;
            a {
                @extend %font-default;
                font-size: 10px;
                color: $default-color;
                text-decoration: none; }
            @include media-breakpoint-down(lg) {
                font-size: 12px; } } }
    &__content {
        @extend %font-default;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        justify-content: center;
        .content-title {
            font-size: 24px;
            margin-bottom: 5px;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.05rem; }
        .content-gif {
            max-width: 92px;
            max-height: 92px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            top: 0px; } } }
