.main {
    &__play {
        // overflow: hidden
        position: relative;
        z-index: 10;
        // padding-top: 65px
        padding-bottom: 250px;
        &::before {
            content: '';
            display: block;
            bottom: -33px;
            left: 0;
            width: 100%;
            height: 143px;
            position: absolute;
            background-image: url(../images/line-bottom.png);
            background-position: center center;
            background-repeat-y: no-repeat;
            background-repeat-x: repeat;
            background-size: auto 100%;
            z-index: 1; } } }
.play {
    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        margin-bottom: 34px;
        .content-title {
            @extend %font-subtitle;
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            line-height: 2.25rem;
            letter-spacing: 0.02875rem;
            color: $mainFontColorSubtitle; } }
    &__conteiner {}
    .play-content {
        padding: 0px 0px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center; }
    &__content {
        padding: 0px 0px 0px 0px;
        // --bs-gutter-y: 1rem
        // --bs-gutter-x: -8rem
        // .content-title
        //     font-size: 30px
        //     @extend %font-default
        //     font-style: normal
        //     font-weight: 600
        //     line-height: 120%
        //     letter-spacing: -0.05rem
        //     text-transform: uppercase
        //     color: $mainFontColor
        //     margin-bottom: 10px
        // .content-description
        //     color: $mainFontColor
        //     @extend %font-default
        //     font-size: 16px
        //     font-style: normal
        //     font-weight: 500
        //     line-height: 1.25rem
        //     letter-spacing: -0.05rem
        //     margin-bottom: 10px
        // .content-line
        //     max-width: 368px
        //     max-height: 1px
        //     width: 100%
        //     height: 1px
        //     background: linear-gradient(90deg, rgba(205, 65, 43, 0.00) 0%, #CD412B 50%, rgba(205, 65, 43, 0.00) 100%)
        //     margin-top: 15px
 }        //     margin-bottom: 15px
    .play-card {
        position: relative;
        cursor: pointer;
        // overflow: hidden
        display: inline-block;
        max-height: 444px;
        overflow: hidden;
        max-width: 368px;
        height: 100%;
        width: 100%;
        &::before {
            content: '';
            position: absolute;
            display: block;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            background: url(../images/card-mask.png);
            z-index: 2;
            background-position: center top;
            background-repeat: no-repeat;
            background-size: 100% auto; }
        @include media-breakpoint-down(md) {
            max-height: 255px; }
        @include media-breakpoint-down(lg) {
            max-height: 255px; }
        &:hover .play-static {
            opacity: 0; }
        &:hover .play-overlay {
            opacity: 1; }
        &:hover > .play-shadow {
 }            // transform: scale(1.1)
        &:hover > .play-background {
            transform: scale(1.1);
            filter: grayscale(1);
 }            // border: 1px solid transparent
        // &:hover > .play-shadow
        //     opacity: 0
        .play-border {
            width: 100%;
            position: absolute;
            height: 100%;
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                background-image: url(../images/border-card.png);
                background-position: center;
                background-repeat: no-repeat;
                left: 0;
                z-index: 2; } }
        .play-background {
            max-width: 100%;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            vertical-align: middle;
            transition: transform $hover;
            z-index: 1;
            position: relative;
            filter: grayscale(0);
 }            // background: rgba(0, 0, 0, 0.60)
        .play-shadow {
            // background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%)
            background: rgba(0, 0, 0, 0.60);
            position: absolute;
            width: 100%;
            // padding: 10px
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
            transition: opacity $hover;
            min-width: 100%;
            // -webkit-mask-image: url(../images/card-mask.png)
            // mask-image: url(../images/card-mask.png)
            // -webkit-mask-position: center center
            // mask-position: center center
            // -webkit-mask-size: 100% 100%
            // mask-size: 100% 100%
            // transform: scale(1.1)
 } }            // border: 1px solid $mainFontColorSubtitle
    // .play-background
    //     width: 100%
    //     height: 100%
    .play-logo {
        // width: 62px
        // height: 62px
        position: relative;
        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
            object-position: center; } }
    .play-title-logo {}
    .play-static {
        position: absolute;
        padding: 60px 40px;
        z-index: 100;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 60px;
        transition: opacity $hover; }
    .play-overlay {
        // padding: 20px 40px
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.80);
        color: $mainFontColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity $hover;
        padding: 16px 16px;
        .content-logo {
            margin-bottom: 8px;
            img {
                object-fit: contain;
                object-position: center;
                width: 100%;
                height: 100%; } }
        .content-description {
            // max-width: 484px
            @extend %font-default;
            color: $mainFontColorSubtitle;
            text-align: center !important;
            font-size: 10px;
            font-size: 0.625rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem;
            letter-spacing: 0.02875rem;
            @include media-breakpoint-down(l) {
                font-size: 12px; } }
        // .content-btn
        //     font-size: 12px
        //     padding: 5px 40px
        //  // &::after
        //  //     left: -3px
        //      // top: -3.2px
        //  // @include media-breakpoint-down(lg)
 } }        //      // padding: 5px 20px
