.go-top {
    align-items: center;
    // background-color: $default-color
    // border: 1px solid transparent
    // border-image: $goldGradient
    // -moz-border-image: $goldGradient
    // -webkit-border-image: $goldGradient
    // border-image-slice: 1
    // border-radius: 100%
    // background-color: transparent
    // background-image: url('../../images/toggle.png')
    // background-position: center center
    // background-repeat: no-repeat
    // background-size: cover
    border: 1px solid $success-color;
    border-radius: 100%;
    bottom: 30px;
    color: $white;
    cursor: pointer;
    display: none;
    font-size: 24px;
    position: fixed;
    right: 15px;
    text-decoration: none;
    transition: background-color .2s ease;
    opacity: .5;
    justify-content: center;
    height: 50px;
    width: 50px;
    // border-radius: 100%
    z-index: 20;
    @include media-breakpoint-up(lg) {
        right: 30px; }

    &.is-show {
        display: flex;

        &:hover {
            background: $hover-color;
            transition: background .2s ease;
            opacity: 1;
            border: 1px solid $hover-color; }
        &:hover>.icon {
            fill: $dark; } }

    .icon {
        fill: $success-color; }
    .icon-arrow-up {
        width: 0.8em; } }
