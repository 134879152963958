// Starter template
@import './page/page';
@import './page/play';
@import './page/history';
@import "./header/header";
@import "./logo/logo";
@import "./mainmenu/mainmenu";
@import "./hamburger/hamburger";
@import "./breadcrumbs/breadcrumbs";
@import "./content/content";
@import "./sidebar/sidebar";
@import "./module/module";
@import './textblock/textblock';
@import './pagination/pagination';
@import "./footer/footer";
@import "./overlay/overlay";
@import "./background/background";
@import "./lineshadow/lineshadow";
@import "./toggle/toggle";
@import "./slider/slider";
// @import "./preloader/preloader"

// Developer components
