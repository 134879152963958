.main {
    &__history {
        // overflow: hidden
        position: relative;
        z-index: 10;
        padding-top: 120px;
        padding-bottom: 100px;
        &::before {
            content: '';
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 104px;
            position: absolute;
            background-image: url(../images/line-top.png);
            background-position: center center;
            background-repeat-y: no-repeat;
            background-repeat-x: repeat;
            background-size: auto 100%;
            z-index: 100; }
        &::after {
            content: '';
            display: block;
            top: -40px;
            left: 0;
            width: 100%;
            height: 58px;
            position: absolute;
            background: linear-gradient(180deg, rgba(39, 39, 39, 0.00) 0%, #1C1C1C 68.23%, rgba(28, 28, 28, 0.31) 83.85%, rgba(28, 28, 28, 0.00) 100%);
            z-index: 101; } } }

.history {
    position: relative;
    &__images {
        width: 100%;
        display: block;
        position: relative;
        height: 100px;
        .history-img {
            width: 100%;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 0%);
            object-fit: cover;
            object-position: center; } }
    &__conteiner {
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
    &__title {
        display: flex;
        align-items: center;
        gap: 5px; }
    &__content {
        display: flex;
        align-items: center;
        // display: flex
        // flex-direction: column
        // align-items: center
        // justify-content: center
        .content-title {
            padding: 0px 0px 0px 0px;
            margin: 0px 0px 0px 0px;
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            line-height: 2.25rem;
            letter-spacing: 0.02875rem;
            color: $mainFontColorSubtitle;
            // width: fit-content
            @extend %font-subtitle;
            position: relative;
            margin-bottom: 20px;
            @include media-breakpoint-down(lg) {
                font-size: 42px;
 } }                // text-align: center !importan
        .content-description {
            @extend %font-primary;
            color: $mainFontColor;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 1rem;
            letter-spacing: 0.05rem; }
        .content-line {
            max-width: 368px;
            max-height: 1px;
            width: 100%;
            height: 1px;
            background: linear-gradient(90deg, rgba(205, 65, 43, 0.00) 0%, #CD412B 50%, rgba(205, 65, 43, 0.00) 100%);
            margin-top: 15px;
            margin-bottom: 15px; }
        .content-btn {
            // padding: 2px 60px
            @include media-breakpoint-down(lg) {
                // margin-bottom: 24px
                margin: 10px auto; } } } }
