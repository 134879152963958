.background-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: 825px;
    overflow: hidden;
    z-index: 1;
    background: rgba(57, 57, 57, 0.4);
    // &:after
    //     content: ""
    //     position: absolute
    //     z-index: 2
    //     top: 0
    //     left: 0
    //     right: 0
    //     bottom: 0
    //     background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 31.25%, rgba(0, 0, 0, 0) 63.54%, rgba(0, 0, 0, 0.2) 100%), radial-gradient(13.7% 17.99% at 50% 35.3%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(18.46% 14.35% at 51.2% 38.39%, rgba(0, 0, 0, 0.4) 23.4%, rgba(0, 0, 0, 0) 100%)
    &__box {
        text-align: center;
        margin: 0 -1000px;
        height: 100%; }
    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    &__images {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%; } }
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: linear-gradient(45deg, #141312 0%, rgba(20, 19, 18, 0.00) 100%); }

    &__left {
        background: url(../images/background-promo-left-artifacts.png);
        max-width: 218px;
        max-height: 492px;
        min-height: 200px;
        min-width: 180px;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 10%;
        bottom: 0;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 2;
        background-position: center bottom; }
    &__right {
        background: url(../images/background-promo-right-artifacts.png);
        width: 100%;
        height: 100%;
        max-width: 218px;
        max-height: 492px;
        min-height: 200px;
        min-width: 180px;
        position: absolute;
        right: -10px;
        top: 10%;
        // bottom: 0
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 2;
        background-position: center bottom; } }
