/**
 * Font
 * ---------------------------------------------
 */
$font-family-base: 'Minecraft Rus', 'Futura PT' ,'Minecraft Evenings' sans-serif;
$enable-smooth-scroll: true;

/**
 * Colors
 * ---------------------------------------------
 */
$white:    #fff;
$gray-100: #FAFAFA;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;
$dark:     $gray-900;

$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;



$link-color: #A70200;
$link-hover-color: #A70200;
// $custom-theme-colors: (
// 	"dark": $dark
// );

/**
 * Spacing
 * ---------------------------------------------
 */
$spacer: 1rem;
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    10: ($spacer * 0.625), // 10px
    15: ($spacer * 0.9375), // 15px
    20: ($spacer * 1.25), // 20px
    25: ($spacer * 1.5625), // 25px
    30: ($spacer * 1.875), // 30px
    40: ($spacer * 2.5), // 40px
    50: ($spacer * 3.125), // 50px
    60: ($spacer * 3.75), // 60px
    70: ($spacer * 4.375), // 70px
    80: ($spacer * 5), // 80px
    90: ($spacer * 5.625), // 90px
    100: ($spacer * 6.25) // 100px
);

/**
 * Buttons
 * --------------------------------------
 */
$btn-border-radius: 5px;
$btn-border-radius-lg: 6px;
$btn-border-radius-sm: 4px;

$btn-font-size: 14px;
$btn-font-size-sm: 12px;
$btn-font-size-lg: 16px;

$btn-padding-y-lg: 11.5px;

/**
 * Shadow
 * ---------------------------------------------
 */
$box-shadow-sm-soft: 0 4px 10px rgba($dark, .075);

/**
 * Cards
 * ---------------------------------------------
 */
$card-cap-bg: rgba($gray-100, 0.3);
$card-cap-padding-y: 15px;
$card-cap-padding-x: 20px;
$card-border-color: rgba($gray-200, 0.95);
$card-spacer-x: 20px;
$card-spacer-y: 15px;

/**
 * Body
 * ---------------------------------------------
 */
$font-size-base: 0.875rem;
$body-color: $mainFontColor;
$body-bg: #131313;
/**
 * Breadcrumb
 * ---------------------------------------------
 */
$breadcrumb-margin-bottom: 30px;

/**
 * Forms
 * ---------------------------------------------
 */
$input-font-size: 14px;
$input-font-size-lg: 14px;
$input-border-radius-lg: 5px;
$input-padding-y-lg: 12px;
$input-border-color: $gray-300;

$form-label-font-weight: 500;
$form-check-input-border-radius: 0.175em;

/**
 * Tables
 * ---------------------------------------------
 */
$table-cell-padding-x: 15px;
$table-cell-padding-y: 10px;

/**
 * Tooltips
 * ---------------------------------------------
 */
$tooltip-font-size: 12px;

/**
 * Modal
 * ---------------------------------------------
 */
$modal-md: 450px;

/**
 * Progress
 * ---------------------------------------------
 */


$progress-bar-animation-timing: 0.7s linear infinite;

$carousel-control-color: $default-color;
$carousel-indicator-active-bg: $primary-color;
$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
