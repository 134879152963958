.lineshadow {
    width: 100%;
    position: absolute;
    height: 160px;
    overflow-x: hidden;
    top: 0;
    transform: translate(0, -50%);
    z-index: 15;
    .liner {
        position: relative;
        width: 100%;
        height: 100%;
        background: $overflowLiner;
        // &::after
        //     content: ''
        //     display: block
        //     width: 100%
        //     height: 50%
        //     // min-height: 145px
        //     // max-height: 145px
        //     position: absolute
        //     z-index: 1
        //     background: $overflowLiner
        //     top: 0
        //     // left: 0
        //     transform: rotate(0deg)
        // &::before
        //     content: ''
        //     display: block
        //     width: 100%
        //     height: 50%
        //     position: absolute
        //     z-index: 1
        //     background: $overflowLiner
        //     top: 30%
 } }        //     transform: rotate(180deg)
